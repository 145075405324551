import React, {useState} from 'react';

import {Button, Modal} from "react-bootstrap"

import ContactForm from "./contactForm"

import {navigate} from "gatsby"

import axios from "axios"

export default (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onSubmit = (values) => {
    let data = []

    Object.keys(values).map(key => data.push([key, values[key]]))

    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(process.env.RECAPTCHA_KEY, {action: 'homepage'}).then((recaptchaToken) => {
        axios({
          method: 'post',
          url: `https://feedback.almet22.ru/send-message`,
          data: {
            recaptchaToken,
            form: {
              title: `${props.title}`,
              data
            }
          }
        }).catch(e => console.log(e)).then(() => {
          navigate(`/thankYou/`, {
            state: {
              prevPath: props.location.pathname
            }
          })
        });
      });
    });

   
  };

  return (
    <>
      <Button variant={props.variant} onClick={handleShow} className={props.className}>
        {props.children}
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ContactForm
            hidden={false}
            onSubmit={onSubmit}
            submitText="Отправить сообщение"
          />
        </Modal.Body>
      </Modal>
    </>
  );

}
